import React, { useEffect } from "react";
import { Box } from "@herb/ui";
import "./index.css";
import $ from "jquery";

const HerbPlusComments = ({ direction = "right" }) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      $(window).ready(() => {
        if ($(".animateLeft").infiniteslide) {
          $(".animateLeft").infiniteslide({
            speed: 50,
            clone: 3
          });
          $(".animateRight").infiniteslide({
            direction: "right",
            speed: 40,
            clone: 3
          });
        }
      });
    }
  }, []);
  return (
    <>
      <div className="commentsMain">
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "195px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "120px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "527px"
              }}
            >
              <p>
                “The best place for{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  cannabis marketing.
                </span>
                ”
              </p>
            </div>
            <div className="commentDiv" css={{ minWidth: "555px" }}>
              <p>
                “Always{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  incredibly helpful and responsive.”
                </span>
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "195px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "120px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "155px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "87.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "476px"
              }}
            >
              <p>
                “Herb{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  made my life
                </span>{" "}
                so much{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  easier
                </span>
                .”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "231px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Solid results
                </span>
                .”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "423px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Excellent reach
                </span>{" "}
                for our brand.”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "155px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "87.5px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "114px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "62.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "846px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Incredible results
                </span>{" "}
                through enhanced{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  strategy and proper planning.
                </span>
                ”
              </p>
            </div>
            <div className="commentDiv" css={{ minWidth: "412px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Impeccable communication.
                </span>
                ”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "114px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "62.5px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "147px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "85.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "509px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  The best online cannabis community.
                </span>
                ”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "685px" }}>
              <p>
                “They consistently go a step further to{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  keep us happy.
                </span>
                ”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "147px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "85.5px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "100px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "46.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "504px"
              }}
            >
              <p>
                “Helps{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  increase our brand
                </span>{" "}
                awareness.”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "301px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Great
                </span>{" "}
                to work with.”
              </p>
            </div>
            <div className="commentDiv" css={{ minWidth: "454px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Changed the vision
                </span>{" "}
                of my brand.”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "100px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "46.5px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "100px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "unset",
                  border: "none"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "566px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  The best possible results
                </span>{" "}
                from campaigns.”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "797px" }}>
              <p>
                “Constantly looking for ways to fully{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  optimize those campaigns.
                </span>
                ”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "100px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "unset",
                  border: "none"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "114px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "62.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "606px"
              }}
            >
              <p>
                “Herb’s team has been{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  a pleasure to work with.
                </span>
                ”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "661px" }}>
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Personalized approach
                </span>{" "}
                to integration of our brand.”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "114px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "62.5px"
                }
              }}
            ></div>
          </div>
          <div className="row">
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                minWidth: "185px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "112.5px"
                }
              }}
            ></div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px",
                minWidth: "265px"
              }}
            >
              <p>
                “Always{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  support.
                </span>
                ”
              </p>
            </div>{" "}
            <div className="commentDiv" css={{ minWidth: "842px" }}>
              <p>
                “The Herb ecosystem helps{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  push quality traffic
                </span>{" "}
                back to our website.”
              </p>
            </div>
            <div
              className="commentDivBg"
              css={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                minWidth: "185px",
                "@media screen and (max-width:1024px)": {
                  minWidth: "112.5px"
                }
              }}
            ></div>
          </div>
        </Box>
      </div>
      <div className="commentsMainMobile">
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div className="animateRight">
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “The best place for{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  cannabis marketing.
                </span>
                ”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “Always{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  incredibly helpful and responsive.”
                </span>
              </p>
            </div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “Herb{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  made my life
                </span>{" "}
                so much{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  easier
                </span>
                .”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Solid results
                </span>
                .”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Excellent reach
                </span>{" "}
                for our brand.”
              </p>
            </div>
          </div>

          <div className="animateLeft">
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Incredible results
                </span>{" "}
                through enhanced{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  strategy and proper planning.
                </span>
                ”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Impeccable communication.
                </span>
                ”
              </p>
            </div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  The best online cannabis community.
                </span>
                ”
              </p>
            </div>{" "}
            <div className="commentDiv">
              <p>
                “They consistently go a step further to{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  keep us happy.
                </span>
                ”
              </p>
            </div>
          </div>

          <div className="animateRight">
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “Helps{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  increase our brand
                </span>{" "}
                awareness.”
              </p>
            </div>{" "}
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Great
                </span>{" "}
                to work with.”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Changed the vision
                </span>{" "}
                of my brand.”
              </p>
            </div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  The best possible results
                </span>{" "}
                from campaigns.”
              </p>
            </div>{" "}
            <div className="commentDiv">
              <p>
                “Constantly looking for ways to fully{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  optimize those campaigns.
                </span>
                ”
              </p>
            </div>{" "}
          </div>

          <div className="animateLeft">
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “Herb’s team has been{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  a pleasure to work with.
                </span>
                ”
              </p>
            </div>
            <div className="commentDiv">
              <p>
                “
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  Personalized approach
                </span>{" "}
                to integration of our brand.”
              </p>
            </div>
            <div
              className="commentDiv"
              css={{
                marginLeft: "26px"
              }}
            >
              <p>
                “Always{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  support.
                </span>
                ”
              </p>
            </div>{" "}
            <div className="commentDiv">
              <p>
                “The Herb ecosystem helps{" "}
                <span
                  css={{
                    background: "#F7FFE3"
                  }}
                >
                  push quality traffic
                </span>{" "}
                back to our website.”
              </p>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default HerbPlusComments;
